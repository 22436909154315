import AdvantageCard from './AdvantageCard';
import styles from './AdvantagesGens.module.scss';

const AdvantagesGens = ({ advantages }) => {
    return (
        <section className={styles.advantages}>
            <h2 className={styles.title}>Преимущества</h2>
            <div className={styles.grid}>
                {advantages.map((advantage, index) => (
                    <AdvantageCard
                        key={index}
                        icon={advantage.icon}
                        title={advantage.title}
                        text={advantage.text}
                    />
                ))}
            </div>
        </section>
    );
};
export default AdvantagesGens