import { useState, useEffect, useRef } from 'react';
import Crums from '../breadCrums/Crums';
import styles from './Banner.module.scss';
import ModalCallback from '../ModalCallback/ModalCallback';

const Banner = ({ image, title, description, crumsData = [['generators', 'Генераторы']], showModal }) => {
    //const [showModal, setShowModal] = useState(false);

    const handleConsultation = () => {
        showModal(true);
    };

    const [overlayHeight, setOverlayHeight] = useState(0);
    const decprRef = useRef(null); // Ссылка на элемент .decpr

    useEffect(() => {
        if (decprRef.current) {
            // Устанавливаем высоту overlay с запасом
            setOverlayHeight(decprRef.current.offsetHeight + 40); // Добавляем 20px к высоте
        }
    }, [title, description]);

    return (
        <div className={styles.banner}>
            <div className={styles.layout} >
                <div class={styles.con} style={{ backgroundImage: `url(${image})` }}>
                    <div class={styles.lowerHalf}></div>
                </div>

            </div>
            <div className={styles.overlay} style={{ height: `${overlayHeight}px` }}></div>
            <div className={styles.contentBox}>
                <Crums data={crumsData} lightTheme={true} />
                <div className={styles.decpr} ref={decprRef}>
                    <h1 className={styles.title}>{title}</h1>
                    <div
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <button
                        className={styles.button}
                        onClick={handleConsultation}
                    >
                        Получить консультацию
                    </button>
                </div>
            </div>
            {/*showModal && <ModalCallback onClose={() => setShowModal(false)} />*/}
        </div>
    );
};
export default Banner