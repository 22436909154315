import React, { useState } from 'react';
import { text } from '../../../translate'
import { useGetGenFaq } from '../../hooks/useGetGenFaq';
import styles from './AboutGeneratorsInfo.module.scss'
import arrow from './arrow-right.svg'
const AboutGeneratorsInfo = () => {
    const lang = 'ru'
    const { faq, loading } = useGetGenFaq();
    const [expandedQuestionId, setExpandedQuestionId] = useState(null);

    const handleIconClick = (questionId) => {
        setExpandedQuestionId((prevId) => (prevId === questionId ? null : questionId));
    };

    if (loading) {
        return <div>Загрузка вопросов...</div>;
    }
    return (
        <div className={styles.wrapper + ' container'}>
            <div className={styles.gridContainer}>
                <div className={styles.item}>
                    <div className={styles.frameBig}>
                        <span className={styles.titleNumber}>20 000</span>
                    </div>
                    <span className={styles.text}>
                        Газопоршневые устройства (ГПУ) мощностью 350-1200 кВт — идеальный вариант
                        для собственной генерации
                    </span>
                </div>
                <div className={styles.item}>
                    <div className={styles.frameBig}>
                        <span className={styles.titleNumber}>5000</span>
                    </div>
                    <span className={styles.text}>
                        Газопоршневые устройства (ГПУ) мощностью 350-1200 кВт — идеальный вариант
                        для собственной генерации
                    </span>
                </div>
                <div className={styles.item}>
                    <div className={styles.frameBig}>
                        <span className={styles.titleNumber}>5</span>
                    </div>
                    <span className={styles.text}>
                        Газопоршневые устройства (ГПУ) мощностью 350-1200 кВт — идеальный вариант
                        для собственной генерации
                    </span>
                </div>
            </div>
            <span className={styles.answers}>Дополнительная информация</span>
            <div className={styles.questionsContainer}>
                {faq.map((item) => (
                    <div key={item.id} className={styles.questionFrame}>
                        <div className={styles.questionContainer}>
                            <span className={styles.question} onClick={() => handleIconClick(item.id)}>{item.question}</span>
                            <div
                                className={`${styles.iconContainer} ${expandedQuestionId === item.id ? styles.iconRotated : ''
                                    }`}
                                onClick={() => handleIconClick(item.id)}
                            >
                                <img
                                    className={styles.icon}
                                    src={arrow}
                                    alt=''
                                />
                            </div>
                        </div>
                        {expandedQuestionId === item.id && (
                            <div className={styles.answer}>
                                <span dangerouslySetInnerHTML={{ __html: item.answer }} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AboutGeneratorsInfo
