import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl, imageUrl } from '../config/config'

export const useGetGenGallery = () => {
    const [gallery, setGallery] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGallery = async () => {
            try {
                const response = await axios.get(`${baseUrl}/gen-gallery`);
                const formattedData = response.data.map(item => ({
                    id: item.id,
                    type: item.media_type,
                    url: `${imageUrl}${item.store_url}`,
                    order: item.order_number,
                    createdAt: new Date(item.created_at),
                }));
                setGallery(formattedData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchGallery();
    }, []);

    return { gallery, loading, error };
};
