import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl, imageUrl } from '../config/config'

export const useGetGenBanner = () => {
    const [banner, setBanner] = useState({
        id: null,
        title: '',
        description: '',
        image: '',
        createdAt: null,
        crumsData: [['generators', 'Генераторы']]
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBanner = async () => {
            try {
                const response = await axios.get(`${baseUrl}/info/gen-banner`);
                const data = response.data;
                setBanner({
                    id: data.id,
                    title: data.title,
                    description: data.description,
                    image: `${imageUrl}${data.store_url}`,
                    createdAt: new Date(data.created_at),
                    crumsData: [['generators', 'Генераторы']],
                });
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchBanner();
    }, []);

    return { banner, loading, error };
};