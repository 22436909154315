import axios from 'axios'
import { baseUrl } from '../config/config'
//POST /api/v1/marketing/Placement Request
export const postSendFeedback = ({
   state,
   setTextModal,
   setPopup,
   setState,
   setWhatsApp,
   setTg,
   setPhone,
}) => {
   axios
      .post(`${baseUrl}/marketing/datacenter-placement`, state, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      .then(() => {
         setTextModal('Ваша заявка успешно отправлена!')
         setPopup(true)
         setTimeout(() => {
            setState({
               name: '',
               phones: [{ messenger: 'phone', phone: '' }],
               item_count: 1,
               item: '',
            })
            if (setWhatsApp)
               setWhatsApp({ phone: '', messenger: 'whatsapp' })
            if (setTg)
               setTg({ phone: '', messenger: 'telegram' })
            if (setPhone)
               setPhone({ phone: '', messenger: 'phone' })
         }, 1500)
      })
      .catch(() => {
         setTextModal('Произошла ошибка при отправке заявки. Попробуйте снова.');
      })
}

export const postGeneratorSendFeedback = ({
   state,
   setTextModal,
   setPopup,
   setState,
}) => {
   axios
      .post(`${baseUrl}/generators/lead`, state, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      .then(() => {
         setTextModal('Ваша заявка успешно отправлена!');
         setPopup(true);
         setTimeout(() => {
            setState({ name: '', phone: '' });
         }, 1500);
      })
      .catch(() => {
         setTextModal('Произошла ошибка при отправке заявки. Попробуйте снова.');
      });
};