import styles from './AdvantageCard.module.scss';
const AdvantageCard = ({ icon, title, text }) => {
    return (
        <div className={styles.card}>
            <div
                className={styles.imageBackground}
                style={{ backgroundImage: `url(${icon})` }}
            ></div>
            <div className={styles.content}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        </div >
    );
};

export default AdvantageCard