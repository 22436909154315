import styles from './About.module.scss';

const About = ({ text, image }) => {
    return (
        <section className={styles.about}>
            <h2 className={styles.title}>О нас</h2>
            <div className={styles.content}>
                <div className={styles.text}
                    dangerouslySetInnerHTML={{ __html: text }}
                />
                <div className={styles.image}>
                    <img src={image} alt="Газопоршневой генератор" />
                </div>
            </div>
        </section>
    );
};

export default About