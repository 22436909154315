import { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl, imageUrl } from '../config/config';

export const useGetGenAdvantages = () => {
    const [advantages, setAdvantages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAdvantages = async () => {
            try {
                const response = await axios.get(`${baseUrl}/advantages`);
                const data = response.data;

                const formattedAdvantages = data.map((item) => ({
                    icon: `${imageUrl}${item.store_url}`,
                    title: item.title,
                    text: item.description
                }));

                setAdvantages(formattedAdvantages);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAdvantages();
    }, []);

    return { advantages, loading, error };
};
