import classes from './Crums.module.scss'
// import homeIcon from '../../../accests/image/home-2.svg'
import { Link } from 'react-router-dom'

const Crums = ({ data, lightTheme }) => {
   if (!Array.isArray(data)) {
      return null;
   }
   return (
      <div className={`${classes.crums} ${lightTheme ? classes.light : ''}`}>
         <Link to='/'>
            {/* <img src={homeIcon} alt='home' /> */}
            Главная
         </Link>
         <p>/</p>
         {data.map((element, index) => {
            if (data.length > index + 1) {
               return (
                  <div key={index}>
                     <div>
                        <Link to={'/' + element[0]} className={classes.crumsActive}>
                           {element[1]}
                        </Link>
                     </div>
                     <p> / </p>
                  </div>
               )
            } else {
               return (
                  <span key={index + 1} className={classes.limited}>
                     {element[1]}
                  </span>
               )
            }
         })}
      </div>
   )
}

export default Crums
