import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import SidePopup from '../modalsUtility/SidePopup';
import ImgSucc from '../../../accests/image/success.svg';
import Cross from '../../../accests/image/crossIcon.svg';
import classes from './ModalCallback.module.scss';
import { postSendFeedback } from '../../hooks/usePostSendFeedback';
import { phoneFormating } from '../comFn';

const ModalCallback = ({ onClose }) => {
    const navigate = useNavigate()
    const [state, setState] = useState({
        name: '',
        phones: [], // Список выбранных мессенджеров с номерами
        item_count: 1,
        item: '',
    });
    const [popup, setPopup] = useState(false);
    const [textModal, setTextModal] = useState('');

    const handleCheckboxChange = (method) => {
        setState((prevState) => {
            const phones = prevState.phones.some((phone) => phone.messenger === method)
                ? prevState.phones.filter((phone) => phone.messenger !== method) // Убираем метод, если он был выбран
                : [...prevState.phones, { messenger: method, phone: '' }]; // Добавляем новый метод, если не выбран
            return { ...prevState, phones };
        });
    };

    const handlePhoneChange = (method, value) => {
        setState((prevState) => {
            const phones = prevState.phones.map((phone) =>
                phone.messenger === method
                    ? { ...phone, phone: method === 'phone' ? phoneFormating(value) : value }
                    : phone
            );
            return { ...prevState, phones };
        });
    };

    const isFormValid = state.name && state.phones.some((phone) => phone.phone);

    const sendData = () => {
        if (!isFormValid) {
            setTextModal('Заполните все обязательные поля!');
            setPopup(true);
            return;
        }

        postSendFeedback({
            state,
            setTextModal,
            setPopup,
            setState,
        });
    };

    function scrollup(url = '/') {
        window.scrollTo(0, 0)
        navigate(url)
    }
    return (
        <>
            <div className={classes.overlay} onClick={(e) => e.target === e.currentTarget && onClose()}>
                <div className={classes.modalCentered}>
                    <div className={classes.modalContent}>
                        <button className={classes.closeButton} onClick={onClose}>
                            ✕
                        </button>
                        <h3 className={classes.modalTitle}>Оставьте свои данные</h3>
                        <p className={classes.modalDescription}>и мы свяжемся с вами в ближайшее время</p>
                        <input
                            type="text"
                            placeholder="Ваше имя"
                            value={state.name}
                            onChange={(e) => {
                                setState({ ...state, name: e.target.value });
                            }}
                            className={classes.input}
                        />
                        <div className={classes.checkboxGroup}>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={state.phones.some((phone) => phone.messenger === 'phone')}
                                    onChange={() => handleCheckboxChange('phone')}
                                />
                                Телефон
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={state.phones.some((phone) => phone.messenger === 'telegram')}
                                    onChange={() => handleCheckboxChange('telegram')}
                                />
                                Telegram
                            </label>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={state.phones.some((phone) => phone.messenger === 'whatsapp')}
                                    onChange={() => handleCheckboxChange('whatsapp')}
                                />
                                WhatsApp
                            </label>
                        </div>
                        {state.phones.map((phone) => {
                            if (phone.messenger === 'phone') {
                                return (
                                    <input
                                        key="phone"
                                        type="text"
                                        placeholder="Ваш телефон"
                                        value={phone.phone}
                                        onChange={(e) => handlePhoneChange('phone', e.target.value)}
                                        className={classes.input}
                                    />
                                );
                            }
                            if (phone.messenger === 'telegram') {
                                return (
                                    <input
                                        key="telegram"
                                        type="text"
                                        placeholder="@Ник в Telegram"
                                        value={phone.phone}
                                        onChange={(e) => handlePhoneChange('telegram', e.target.value)}
                                        className={classes.input}
                                    />
                                );
                            }
                            if (phone.messenger === 'whatsapp') {
                                return (
                                    <input
                                        key="whatsapp"
                                        type="text"
                                        placeholder="Ваш номер в WhatsApp"
                                        value={phone.phone}
                                        onChange={(e) => handlePhoneChange('whatsapp', e.target.value)}
                                        className={classes.input}
                                    />
                                );
                            }
                            return null;
                        })}
                        <button onClick={sendData}
                            className={`${classes.submitButton} ${!isFormValid ? classes.disabled : ''}`}
                            disabled={!isFormValid}>
                            Отправить запрос
                        </button>
                        <p className={classes.privacyPolicy}>
                            Нажимая на кнопку, вы соглашаетесь с <a href="#" onClick={() => scrollup('/rights')}>Политикой конфиденциальности</a>
                        </p>
                    </div>
                </div>
            </div>
            {popup && (
                <SidePopup dataState={true}>
                    <span
                        className={
                            !state.name || !state.phone || state.phone.length < 10
                                ? classes.crossWrapper
                                : ''
                        }
                    >
                        <img
                            src={
                                !state.name || !state.phone || state.phone.length < 10
                                    ? Cross
                                    : ImgSucc
                            }
                            alt="success"
                        />
                    </span>
                    <span></span>
                    <span>
                        <p>{textModal}</p>
                    </span>
                </SidePopup>
            )}
        </>
    );
};

export default ModalCallback;
