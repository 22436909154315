import React, { useState, useEffect } from 'react';
import './CostCalculator.scss'

const CostCalculator = ({ models }) => {
    const [selectedModelId, setSelectedModelId] = useState('');
    const [gasPrice, setGasPrice] = useState('');
    const [gasConsumption, setGasConsumption] = useState('');
    const [costPerKWh, setCostPerKWh] = useState('');
    console.log(models);

    // Расчет стоимости при изменении входных данных
    useEffect(() => {
        // Проверяем, что выбрана модель и введена цена на газ
        if (selectedModelId && gasPrice) {
            // 1. Приводим selectedModelId к числу:
            const modelIdNumber = parseInt(selectedModelId, 10);

            // Находим соответствующий объект модели
            const selectedModel = models.find((model) => model.id === modelIdNumber);

            if (selectedModel) {
                // 2. Явно приводим строки, приходящие в модели, к числу:
                const gasConsumptionValue = parseFloat(selectedModel.gasConsumption);
                const addGasPriceValue = parseFloat(selectedModel.addGasPrice);

                // 3. Сам gasPrice из инпута тоже парсим:
                const gasPriceValue = parseFloat(gasPrice);

                // 4. Выполняем расчёты, аккуратно сложив числа:
                const calculatedConsumption = gasPriceValue * gasConsumptionValue + addGasPriceValue;

                // Установка расхода газа
                setGasConsumption(calculatedConsumption.toFixed(2));

                // Расчёт стоимости за 1 кВт/ч
                const costCalculation = calculatedConsumption * gasPriceValue;
                setCostPerKWh(costCalculation ? costCalculation.toFixed(2) : '');
            }
        } else {
            setGasConsumption('');
            setCostPerKWh('');
        }
    }, [selectedModelId, gasPrice, models]);


    return (
        <div className="calculator-container">
            <div className='calculator-layout'>
            </div>
            <div className="calculator-header">
                <h2 className="calculator-title">Калькулятор стоимости</h2>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className='firstLightning' viewBox="0 0 31 38" fill="none">
                        <path d="M17.8742 16.028L30.1294 19.3118L8.25521 37.9208L12.4904 22.1147L0.235242 18.831L22.1094 0.221986L17.8742 16.028Z" fill="#BDC3D1" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" className='secondLightning' viewBox="0 0 46 57" fill="none">
                        <path d="M27.0344 24.0633L45.2059 28.9323L12.6663 56.9186L19.0191 33.2095L0.847615 28.3405L33.3873 0.35418L27.0344 24.0633Z" fill="#BDC3D1" />
                    </svg>
                </div>
            </div>

            <div className="calculator-form">
                <div className="form-group">
                    <label>Модель</label>
                    <select
                        value={selectedModelId}
                        onChange={(e) => setSelectedModelId(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Выберите модель</option>
                        {models.map((model) => (
                            <option key={model.id} value={model.id}>
                                {model.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label>Стоимость газа за 1м³, руб</label>
                    <input
                        type="number"
                        value={gasPrice}
                        onChange={(e) => setGasPrice(e.target.value)}
                        placeholder="Укажите стоимость газа"
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label>Расход газа, м³/ч</label>
                    <input
                        type="text"
                        value={gasConsumption}
                        readOnly
                        placeholder="Заполняется автоматически"
                        className="form-control readonly"
                    />
                </div>

                <div className="form-group">
                    <label>Стоимость за 1кВт/ч</label>
                    <input
                        type="text"
                        value={costPerKWh}
                        readOnly
                        className="form-control readonly result"
                    />
                </div>

                {/*<div className="formula-note">
                    Вычисляется по формуле: Cummins 350/400: "стоимость газа" x 0.27 + 0.92
                </div>*/}
            </div>
        </div>
    );
};

export default CostCalculator;