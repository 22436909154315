import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config/config'

import gal1 from '../../images/gal1.png'


export const useGetGenerators = (url = '/generators') => {
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(true)

   const generatorsData = [
      {
         "id": 1,
         "title": "FG Wilson P220-1",
         "slug_name": "fg-wilson-p220-1",
         "mdl_name": "P220-1",
         "brand_name": "FG Wilson",
         "weight": 1850,
         "price": 2450000,
         "discount": 150000,
         "total_price": 2300000,
         "description": "Надежный промышленный генератор для постоянного и резервного электроснабжения",
         "characteristics": "Промышленный дизельный генератор",
         "technical_passport_store_url": "techpassport/fg-wilson-p220.pdf",
         "drawing_store_store_url": "drawings/fg-wilson-p220.pdf",
         "nominal_power_kvt": 220,
         "nominal_power_kva": 275,
         "nominal_current": 380,
         "nominal_voltage_min": 380,
         "nominal_voltage_max": 400,
         "nominal_frequency": 50,
         "voltage_regulation": "Электронная",
         "operating_mode": "Основной/Резервный",
         "insulation_class": "H",
         "eng_type": "Perkins 1106C",
         "nominal_speed": 1500,
         "cylinder_number": 6,
         "piston_diameter": 105,
         "piston_stroke": 127,
         "cooling_method": "Жидкостное",
         "oil_consumption": 0.8,
         "gas_consumption": 45.5,
         "launch_method": "Электростартер",
         "working_voltage": "380/220",
         "overall_dimensions": "2800x1100x1760",
         "panel_cutout": "DSE 7320",
         "health_state": "new",
         "temperature_min": -40,
         "temperature_max": 50,
         "humidity_min": 20,
         "humidity_max": 80,
         "cs_weight": 1850,
         "photo_urls": [
            gal1,
         ],
         "created_at": "2024-12-23T10:55:55.558Z",
         "updated_at": "2024-12-23T10:55:55.558Z"
      },
      {
         "id": 2,
         "title": "AKSA APD33A",
         "slug_name": "aksa-apd33a",
         "mdl_name": "APD33A",
         "brand_name": "AKSA",
         "weight": 750,
         "price": 850000,
         "discount": 50000,
         "total_price": 800000,
         "description": "Компактный генератор для дома и малого бизнеса",
         "characteristics": "Дизельный генератор в кожухе",
         "technical_passport_store_url": "techpassport/aksa-apd33a.pdf",
         "drawing_store_store_url": "drawings/aksa-apd33a.pdf",
         "nominal_power_kvt": 33,
         "nominal_power_kva": 41,
         "nominal_current": 95,
         "nominal_voltage_min": 220,
         "nominal_voltage_max": 240,
         "nominal_frequency": 50,
         "voltage_regulation": "AVR",
         "operating_mode": "Резервный",
         "insulation_class": "H",
         "eng_type": "AKSA A4CRX46",
         "nominal_speed": 1500,
         "cylinder_number": 4,
         "piston_diameter": 87,
         "piston_stroke": 102,
         "cooling_method": "Жидкостное",
         "oil_consumption": 0.2,
         "gas_consumption": 8.5,
         "launch_method": "Электростартер",
         "working_voltage": "230/400",
         "overall_dimensions": "1900x950x1250",
         "panel_cutout": "DSE 6020",
         "health_state": "new",
         "temperature_min": -20,
         "temperature_max": 45,
         "humidity_min": 30,
         "humidity_max": 85,
         "cs_weight": 750,
         "photo_urls": [
            gal1,
         ],
         "created_at": "2024-12-23T11:00:00.558Z",
         "updated_at": "2024-12-23T11:00:00.558Z"
      },
      {
         "id": 3,
         "title": "SDMO J88K",
         "slug_name": "sdmo-j88k",
         "mdl_name": "J88K",
         "brand_name": "SDMO",
         "weight": 1200,
         "price": 1350000,
         "discount": 0,
         "total_price": 1350000,
         "description": "Профессиональный генератор средней мощности",
         "characteristics": "Дизельный генератор открытого исполнения",
         "technical_passport_store_url": "techpassport/sdmo-j88k.pdf",
         "drawing_store_store_url": "drawings/sdmo-j88k.pdf",
         "nominal_power_kvt": 88,
         "nominal_power_kva": 110,
         "nominal_current": 158,
         "nominal_voltage_min": 380,
         "nominal_voltage_max": 400,
         "nominal_frequency": 50,
         "voltage_regulation": "Электронная",
         "operating_mode": "Основной",
         "insulation_class": "H",
         "eng_type": "John Deere 4045TF120",
         "nominal_speed": 1500,
         "cylinder_number": 4,
         "piston_diameter": 96,
         "piston_stroke": 115,
         "cooling_method": "Жидкостное",
         "oil_consumption": 0.4,
         "gas_consumption": 22.5,
         "launch_method": "Электростартер",
         "working_voltage": "380/220",
         "overall_dimensions": "2200x1000x1500",
         "panel_cutout": "APM303",
         "health_state": "new",
         "temperature_min": -25,
         "temperature_max": 45,
         "humidity_min": 25,
         "humidity_max": 80,
         "cs_weight": 1200,
         "photo_urls": [
            gal1
         ],
         "created_at": "2024-12-23T12:00:00.558Z",
         "updated_at": "2024-12-23T12:00:00.558Z"
      },
      {
         "id": 4,
         "title": "Energo ED30/230",
         "slug_name": "energo-ed30-230",
         "mdl_name": "ED30/230",
         "brand_name": "Energo",
         "weight": 580,
         "price": 620000,
         "discount": 70000,
         "total_price": 550000,
         "description": "Экономичный генератор для резервного питания",
         "characteristics": "Дизельный генератор в кожухе",
         "technical_passport_store_url": "techpassport/energo-ed30.pdf",
         "drawing_store_store_url": "drawings/energo-ed30.pdf",
         "nominal_power_kvt": 30,
         "nominal_power_kva": 37.5,
         "nominal_current": 81,
         "nominal_voltage_min": 220,
         "nominal_voltage_max": 230,
         "nominal_frequency": 50,
         "voltage_regulation": "AVR",
         "operating_mode": "Резервный",
         "insulation_class": "F",
         "eng_type": "Yanmar 4TNV98",
         "nominal_speed": 1500,
         "cylinder_number": 4,
         "piston_diameter": 85,
         "piston_stroke": 95,
         "cooling_method": "Жидкостное",
         "oil_consumption": 0.15,
         "gas_consumption": 7.8,
         "launch_method": "Электростартер",
         "working_voltage": "230/400",
         "overall_dimensions": "1800x850x1200",
         "panel_cutout": "ComAp IL-NT AMF25",
         "health_state": "new",
         "temperature_min": -15,
         "temperature_max": 40,
         "humidity_min": 30,
         "humidity_max": 80,
         "cs_weight": 580,
         "photo_urls": [
            gal1
         ],
         "created_at": "2024-12-23T13:00:00.558Z",
         "updated_at": "2024-12-23T13:00:00.558Z"
      },
      {
         "id": 5,
         "title": "Cummins C550D5",
         "slug_name": "cummins-c550d5",
         "mdl_name": "C550D5",
         "brand_name": "Cummins",
         "weight": 3850,
         "price": 5200000,
         "discount": 200000,
         "total_price": 5000000,
         "description": "Мощный промышленный генератор для крупных объектов",
         "characteristics": "Дизельный генератор открытого исполнения",
         "technical_passport_store_url": "techpassport/cummins-c550d5.pdf",
         "drawing_store_store_url": "drawings/cummins-c550d5.pdf",
         "nominal_power_kvt": 550,
         "nominal_power_kva": 688,
         "nominal_current": 990,
         "nominal_voltage_min": 380,
         "nominal_voltage_max": 400,
         "nominal_frequency": 50,
         "voltage_regulation": "Электронная",
         "operating_mode": "Основной/Резервный",
         "insulation_class": "H",
         "eng_type": "Cummins QSX15G8",
         "nominal_speed": 1500,
         "cylinder_number": 6,
         "piston_diameter": 120,
         "piston_stroke": 145,
         "cooling_method": "Жидкостное",
         "oil_consumption": 1.2,
         "gas_consumption": 115.5,
         "launch_method": "Электростартер",
         "working_voltage": "380/220",
         "overall_dimensions": "3500x1500x2200",
         "panel_cutout": "PowerCommand 3.3",
         "health_state": "new",
         "temperature_min": -50,
         "temperature_max": 55,
         "humidity_min": 20,
         "humidity_max": 90,
         "cs_weight": 3850,
         "photo_urls": [
            gal1
         ],
         "created_at": "2024-12-23T14:00:00.558Z",
         "updated_at": "2024-12-23T14:00:00.558Z"
      }
   ]

   useEffect(() => {
      // Для тестирования: используем тестовые данные
      const useTestData = false;

      if (useTestData) {
         setTimeout(() => {
            setData(generatorsData);
            setIsLoading(false);
         }, 1000); // Имитация задержки запроса
      } else {
         axios
            .get(`${baseUrl}${url}`)
            .then((response) => {
               const data = response.data;
               setData(data);
               setIsLoading(false);
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error);
               setIsLoading(false);
            });
      }
   }, [url]);
   return { data, isLoading }
}
