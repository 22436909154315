import classes from './RegularCD.module.scss'
import { useEffect, useState, useRef } from 'react'
import SidePopup from '../../modalsUtility/SidePopup'
import ImgSucc from '../../../../accests/image/success.svg'
import Cross from '../../../../accests/image/crossIcon.svg'
import {postGeneratorSendFeedback} from '../../../hooks/usePostSendFeedback'
import { phoneFormating } from '../../comFn'

const RegularCB = () => {
   const inputRef = useRef()
   const [state, setState] = useState({
      name: '',
      phone: '',
   })

   const [popup, setPopup] = useState(false)

   useEffect(() => {
      return () => setTimeout(() => setPopup(false), 2100)
   }, [popup])
   const [textModal, setTextModal] = useState('')

   useEffect(() => {
      inputRef.current.addEventListener('keydown', (event) => {
         if (event.keyCode === 8 || event.keyCode === 46) {
            setState({ ...state, phone: '' })
         }
      })
   }, [state])

   const sendData = () => {
      if (!state?.name && !state?.phone) {
         setTextModal('Введите ваше имя и номер телефона!')
      } else if (!state?.name) {
         setTextModal('Введите ваше имя!')
      } else if (!state?.phone) {
         setTextModal('Введите ваш номер телефона!')
      } else if (state?.phone.length < 10) {
         setTextModal('Номер телефона слишком короткий!')
      } else {
         postGeneratorSendFeedback({ state, setTextModal, setPopup, setState })
      }
      setPopup(true)
   }

   return (
      <>
         <div className={classes.callback + ' container'}>
            <div className={classes.callbackInfo}>
               <h4>Оставьте заявку на бесплатную консультацию</h4>
               <p>
                  Нажимая на кнопку, я соглашаюсь на обработку{' '}
                  <a href='/rights'>персональных данных</a>
               </p>
            </div>
            <form
               action='submin'
               className={classes.callbackForm}
               onSubmit={(e) => e.preventDefault()}
            >
               <input
                  type='text'
                  placeholder='Ваше имя'
                  value={state.name}
                  onChange={(e) => {
                     setState({ ...state, name: e.target.value })
                  }}
               />

               <input
                  type='text'
                  placeholder='Ваш телефон'
                  maxLength={15}
                  value={state.phone}
                  ref={inputRef}
                  onChange={(e) => {
                     setState({ ...state, phone: phoneFormating(e.target.value) })
                  }}
               />

               <button onClick={() => sendData()}>Отправить</button>
            </form>
         </div>
         {popup ? (
            <SidePopup dataState={true}>
               <span
                  className={
                     !state.name || !state.phone || state.phone.length < 10
                        ? classes.crossWrapper
                        : ''
                  }
               >
                  <img
                     src={!state.name || !state.phone || state.phone.length < 10 ? Cross : ImgSucc}
                     alt={'success'}
                  />
               </span>
               <span></span>
               <span>
                  <p>{textModal}</p>
               </span>
            </SidePopup>
         ) : null}
      </>
   )
}

export default RegularCB
